<template>
  <div class="DetailsPage">
    <div class="DetailsPage-left">
      <div class="DetailsPage-left-header">
        <div>当前位置 ：</div>
        <div><router-link to="/index">首页</router-link></div>
        <div>/</div>
        <div><router-link to="/maillist">专家库</router-link></div>
        <div>/</div>
        <div>圈子详情</div>
      </div>
      <div class="DetailsPage-left-main">
        <div class="DetailsPage-left-main-header">
          <div class="img-box">
            <!-- <img :src="circleDetail.logo" /></div> -->
            <img
              :src="circleData.avatar"
            />
          </div>

          <div class="speaker-info-box">
            <div class="circleName">
              {{circleData.name}} <span><el-tag size="small" v-if="circleData.label">#{{circleData.label}}</el-tag></span
              >
            </div>
            <div class="circleMsg">
              <span>圈主：{{ ownerData.name }}</span> |<span
                >所属公司：{{ ownerData.company_name }}</span
              >
            </div>
          </div>
        </div>
        <div class="circle-statement">{{circleData.purpose}}</div>

        <div class="main-body">
          <div class="main-body-header">
            <div class="newmessage">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-a-zu333"></use>
              </svg>
              圈子成员
            </div>
          </div>
          <div class="user-list-box">
            <div class="" v-for="item in memberData">
              <div class="user-list-items">
                <div class="header-img">
                  <img :src="item.avatar" />
                </div>
                <div class="message">
                  <div>
                    {{ item.name }}
                  </div>

                  <div>
                    <span class="message_post">{{ item.position }}</span> |
                    <span class="message_company">{{ item.company_name }}</span>
                  </div>
                </div>
                <div class="textarea-date-box">
                  <div class="Operationtype">
                    <div @click="$store.commit('setVideoDialog', { show: true, item:itemData })">
                      <span>谁在看他</span>
                    </div>
                    <div class="dimingpian" @click="$store.commit('setVideoDialog', { show: true, item:itemData })">
                      <span >递名片</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="title">{{ detailsdata.title }}</div>
        <div class="Article-information" v-if="detailsdata">
          <div>
            <i class="iconfont icon-leftfont-104"></i> {{ detailsdata.author }}
                            <div v-if="detailsdata.author">{{ detailsdata.author }}</div>
                <div v-else>德泰中研（转载）</div>
          </div>
          <div>
            <i class="iconfont icon-naozhong2"></i> {{ detailsdata.addTime }}
          </div>
          <div>
            <i class="iconfont icon-yanjing"></i> {{ detailsdata.click }}
          </div>
        </div>
        <div class="text-statement">{{ detailsdata.zhaiyao }}</div>
        <div class="main-text" v-html="detailsdata.content"></div> -->
      </div>
    </div>
    <div class="DetailsPage-right">
       <div class="joinUs-box">
        <div class="joinUs-box-left">
          <div>我要加入</div>
           <div class="desc">{{circleData.name}} <i class="el-icon-arrow-right"></i></div>
        </div>
        <div class="joinUs-box-right">
           <el-image
      style="width: 100px; height: 100px"
      :src="circleData.qrcode"
      fit="contain"></el-image>
        </div>
       </div>
      <div class="group-box">
        <div class="group-header">
          <p class="header-title">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-a-zu337"></use>
            </svg>
            圈子
          </p>
          <!-- <div class="see-more">查看更多</div> -->
        </div>
        <div class="group-item">
          <div class="group-items" v-for="item in grouplist">
            <i class="iconfont icon-a-zu338"></i>
            <svg class="icon icon-a-zu337" aria-hidden="true">
              <use xlink:href="#icon-a-zu337"></use>
            </svg>
            <div class="title">
              <p>{{ item.title }}</p>
            </div>
            <!-- <div class="join"  @click="$store.commit('setVideoDialog', { show: true, url: item.linkUrl,title: item.title })"
            > -->
            <div
              class="join"
              @click="goCircleDetails(item)"
            >
              <i class="iconfont icon-a-zu340"></i>
              加入
            </div>
          </div>
        </div>
      </div>
    </div>
     <video-dialog />
  </div>
</template>

<script>
import {
  Grouplist,

  getCircleDetail,
 
  getDTExhibitsListByConferenceCompanyId,
} from "@/api";
import { imgObj } from "@/utils/const.js";
export default {
  name: "circleDetails",
  components: {
    videoDialog: () => import("@/components/Circle"),
  },
  data() {
    return {
      queryParams: {
        //列表搜索参数
        pageIndex: 1,
        pageSize: 10,
        ids: [],
        name: "",
        text: "",
      },
      circleData:{},
      memberData:[],
      ownerData:{},

     
      expertlist: [],
      grouplist: [],
      pathtext: "",
      pathtextname: "首页",
      id: 0,
      source:0,
      itemData:{},
      conferenceCompanyId: 0,
      expertsCount: 0,
      detailsdata: {},
      hotconsultlist: [],
      productsList: [],
      type: 2,
      pagedetails: "企业专家",
      circleDetail: {},
      conferenceCompanyDetail: {},
      imgIcon: imgObj,
      circleUrl:"https://cardcase.obs.cn-east-3.myhuaweicloud.com/1692320355967"
    };
  },
  methods: {
    async start() {
      getCircleDetail(this.source).then((res)=>{
        console.log(res)
        this.circleData =res.data.data.circle
        this.memberData =res.data.data.member
        this.ownerData =res.data.data.owner
           this.itemData ={
      title:this.circleData.name,
      linkUrl:this.circleData.qrcode
    } 


      })
      // await getDTCompanyDetail(this.id).then((res) => {
      //   console.log("公司详情", res);
      //   this.circleDetail = res.data.data;
      // });
     
     
      
    },
    async getgrouplist() {
      Grouplist().then((res) => {
        console.log("圈子标题", res);
        this.grouplist = res.data;
      });
    },
    goCircleDetails(item) {
      localStorage.setItem("secondpath", JSON.stringify(this.$route.path));
      let routeData = this.$router.resolve({
        name: "circleDetailsPage",
        query: {
          id: item.id|| item.Id,
          source: Number(item.source),
          title: item.title || item.Title,
        },
      });
      window.open(routeData.href, "_blank");
    },
    goUrl(url) {
      window.open(`https://${url}`, "_blank");
    },
  
 
  

  
  },
  mounted() {
    this.conferenceCompanyId = this.$route.query.conferenceCompanyId - 0;
    this.id = this.$route.query.id;
    this.source = this.$route.query.source;
    document.title = this.$route.query.title;
    this.start();
    this.getgrouplist();
  },
};
</script>

<style lang="less" scoped>
.DetailsPage {
  width: 1280px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  .DetailsPage-left {
    width: 100%;
    margin-right: 20px;
    .DetailsPage-left-header {
      display: flex;
      background: white;
      height: 56px;
      line-height: 56px;
      margin-bottom: 20px;
      div {
        margin: 0 5px;
        a {
          color: #c1c1c1;
        }
      }
      div:nth-child(1) {
        margin-left: 20px;
      }
      div:nth-child(4),
      :nth-child(2) {
        cursor: pointer;
      }
    }
    .DetailsPage-left-main {
      background: white;
      padding: 20px;
      .DetailsPage-left-main-header {
        display: flex;
        .img-box {
          text-align: center;
          img {
            width: 86px;
            height: 86px;
            border-radius: 4px;
            overflow: hidden;
            margin: 0 auto;
          }
        }
        .speaker-info-box {
          margin-left: 20px;
          .circleName {
            font-size: 22px;
            margin-bottom: 34px;
            padding-bottom: 0;
            span {
              margin-left: 4px;
            }
          }
          .circleMsg {
            font-style: normal;
            font-weight: 500;
            margin-top: 0;
            margin-bottom: 15px;
            font-size: 14px;
            line-height: 22px;
            color: #aea5a5;
            span:nth-child(2) {
              margin-left: 10px;
            }
          }
          h3:nth-child(3) {
            cursor: pointer;
          }
        }
      }
      .circle-statement {
        background: #f6f6f6;
        padding: 20px;
        color: #888;
        font-size: 16px !important;
        line-height: 1.75rem !important;
        font-family: "PINGFANG-MEDIUM" !important;
      }

      .main-body {
        margin: 20px 0;
        .main-body-header {
          background: white;
          display: flex;
          justify-content: space-between;
          padding: 20px 20px;
          border-bottom: 1px solid #e9e9e9;
          .newmessage {
            font-size: 20px;
            .icon {
              width: 18px;
              height: 18px;
            }
          }
        }
        .user-list-box {
          background: white;
          .user-list-items {
            padding: 20px 20px;
            display: flex;
            align-items: center;
            border-bottom: 0.0625rem solid #e9e9e9;
            .header-img {
              width: 100px;
              margin-right: 50px;
              margin-left: 13px;
              img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
              }
            }
            .message {
              font-size: 15px;
              width: 100%;

              div:nth-child(1) {
                font-size: 20px;
                font-weight: bold;
                span {
                  padding: 5px 16px;
                  font-size: 14px;
                  color: #6a6a6a;
                  margin-left: 20px;
                  background: #f5f6f6;
                }
              }
              div:nth-child(2) {
                margin-top: 15px;
                font-size: 14px;
                color: #9a999e;
              }
              .message_post {
                color: #111111;
                font-weight: 600;
                margin-right: 10px;
              }
              .message_company {
                margin-left: 10px;
              }
            }
            .textarea-date-box {
              display: flex;
              justify-content: space-between;
              // margin-top: -60px;
                  width: 330px;
            
              .Operationtype {
                display: flex;
                font-size: 14px;
                color: #999;
                cursor: pointer;
                div {
                  // padding: 10px 25px;
                  // background: #fff;
                  color: #525252;
                  font-size: 14px;
                  margin-right: 20px;
                  border: 1px solid #ededed;
                  display: flex;
                  align-items: flex-end;
                  border-radius: 4px;
                  span {
                    margin: 8px 12px;
                  }
                   
                }
                // div:nth-child(1) {
                //   padding: 10px 20px;
                //   background: #f5f6f6;
                //   color: #314483;
                //   font-size: 14px;
                // }
                // div {
                //   padding: 10px;
                //   margin-right: 20px;
                // }
                .dimingpian {
                      background: #1C5ADF;
                font-size: 14px;
                color: #fff;
                
              }
              }
            }
            .friends {
              width: 100px;
              color: white;
              background: #314483;
              font-size: 16px;
              padding: 10px 31px;
              border-radius: 29px;
              text-align: center;
              cursor: pointer;
              .iconfont {
                margin-right: 10px;
              }
            }
          }
         
        }
        .main-body-content {
          margin: 30px;
          /deep/img {
            margin: 0 auto !important;
            width: 100% !important;
          }
          /deep/p {
            color: #464646 !important;
            font-size: 16px !important;
            line-height: 1.75rem !important;
            font-family: "PINGFANG-MEDIUM" !important;
            span {
              color: #464646 !important;
              font-size: 16px !important;
              line-height: 1.75rem !important;
              font-family: "PINGFANG-MEDIUM" !important;
            }
          }
        }
      }

      .title {
        font-size: 30px;
        line-height: 45px;
        // font-weight: bold;
      }
      .Article-information {
        margin: 10px 0;
        color: #ababab;
        div:nth-child(1) {
          display: flex;
        }
        div {
          margin-right: 50px;
        }
        .iconfont {
          margin-right: 10px;
          color: #848484;
        }
        display: flex;
      }
      .text-statement {
        background: #f6f6f6;
        padding: 20px;
        color: #888;
      }
      .main-text {
        margin: 20px 0;
        line-height: 34px;
        /deep/img {
          margin: 0 auto;
        }
      }
      .text-operation {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        // div:nth-child(1) {
        //   position: absolute;
        //   left: 0px;
        // }
        // .iconfont {
        //   font-size: 20px;
        //   color: #a8a8a8;
        //   margin-left: 10px;
        //   margin-right: 5px;
        //   cursor: pointer;
        // }
        // div {
        //   padding: 10px 20px;
        //   background: #f5f6f6;
        //   color: #314483;
        //   font-size: 14px;
        //   cursor: pointer;
        //   margin-right: 20px;
        //   span {
        //     margin-left: 5px;
        //   }
        // }
        div {
          padding: 10px 25px;
          background: #fff;
          color: #525252;
          font-size: 14px;
          margin-right: 20px;
          border: 1px solid #ededed;
          display: flex;
          cursor: pointer;
          align-items: flex-end;
          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .DetailsPage-right {
    width: 40%;
    min-width: 360px;
    .joinUs-box{
      background: white;
        display: flex;
        justify-content: space-between;
        .joinUs-box-left{
          margin: 30px;
          div{
            color: #191919;
            
            font-size: 16px;
          }
          .desc{
            font-size: 14px;
            margin: 10px 0;
            color: #1C5ADF;
          }
        }
        .joinUs-box-right{
          margin: 10px 30px;
        }
    }
    .group-box {
      background: white;
      // margin-top: 20px;
      .group-header {
        padding-top: 16px;
        display: flex;
        justify-content: space-between;
        .see-more {
          line-height: 25px;
          padding-right: 20px;
          color: #9a9a9a;
        }
        border-bottom: 1px solid #e9e9e9;
        .header-title {
          font-size: 16px;
          color: #0b1d47;
          margin-top: 0;
          margin-left: 20px;
          margin-bottom: 20px;
          .icon {
            width: 18px;
            height: 18px;
            margin-right: 15px;
            position: relative;
            top: 5px;
          }
          span {
            font-size: 18px;
            font-weight: normal;
            color: #707070;
          }
        }
        .visiting-card {
          height: 20px;
          line-height: 20px;
          padding: 5px 15px;
          margin-top: 0;
          font-size: 14px;
          border-radius: 15px;
          margin-left: 4%;
          cursor: pointer;
        }
        .visiting-card-active {
          background: #b7d9ef65;
        }
        .wechat-group {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          padding: 5px 15px;
          border-radius: 15px;
          margin-left: 15px;
          cursor: pointer;
        }
        .wechat-group-active {
          background: linear-gradient(231deg, #2fb6b649 0%, #128f9325 100%);
        }
      }
      .group-item {
        width: 100%;
        .group-items {
          cursor: pointer;
          padding: 19px 20px;
          display: flex;
          position: relative;
          align-items: flex-end;
          .icon-a-zu337 {
            width: 20px;
            height: 20px;
            display: none;
            position: relative;
            top: 0px;
          }
          &:hover {
            padding: 20.31px 20px;
            background: #fafafa;
            .join {
              // background: #31448342;
              .iconfont {
                color: #314483;
              }
            }
            .title {
              color: #314483;
              // font-weight: bold;
           
            }
            .icon-a-zu338 {
              display: none;
            }
            .icon-a-zu337 {
              display: block;
            }
          }
          i {
            font-size: 20px;
          }
          .title {
            margin-left: 20px;
            margin-top: 2px;
            p {
              width: 210px;
              margin: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          .join {
            padding: 5px 15px;
            position: absolute;
            right: 10px;
            font-size: 14px;
            text-align: center;
            .iconfont {
              color: #cbcbcb;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
</style>